export const PageConfig = {
  
  // AUTHOR
  AUTHOR_NAME: "Gilmar José",
  AUTHOR_EMAIL: "gilamr.neo@gmail.com",
  AUTHOR_GITHUB: "https://github.com/g42puts",
  AUTHOR_LINKEDIN: "https://www.linkedin.com/in/gilmar-jose/",

  // ARCO SOLAR
  FIRST_NAME: "Adjailson",
  SECOND_NAME: "Januario",
  EMAIL: "adjailsonj.ribeiro@gmail.com",
  PHONE_NUMBER: "+55 81 98196-4105",
  ADDRESS: "Rua Jarangari, 3145, Jaboatão dos Guararapes, CEP 54420-120",
  WHATSAPP_CONTATO: "https://api.whatsapp.com/send?phone=5581981964105",
  WHATSAPP_MESSAGE: "https://api.whatsapp.com/send/?phone=5581981964105&text=Ol%C3%A1%2C+gostaria+de+obter+mais+informa%C3%A7%C3%B5es+e+solicitar+or%C3%A7amento+de+energia+solar.&type=phone_number&app_absent=0",
  INSTAGRAM_URL: "https://www.instagram.com/arco_solar_energia_solar/",
  CPNJ: "",

  // Page Config
  THEME_COLOR: "#061550"
}