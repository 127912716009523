import { ReactNode } from "react";
import { Helmet } from "react-helmet";

import Navbar from "./Navbar";
import Footer from "./Footer";

export interface LayoutProps {
  title: string;
  children: ReactNode;
}

const Layout = ({ title, children }: LayoutProps) => {
  return (
    <>
      <Helmet title={title} />
      <Navbar />
      {children}
      <Footer />
    </>
  )
}

export default Layout;