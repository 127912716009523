import { PageConfig } from "src/libs/config";

import { PiPhone } from "react-icons/pi"; // Telefone Icon
import { MdOutlineEmail } from "react-icons/md"; // Email
import { FaInstagram } from "react-icons/fa"; // Instagram Icon

export default function Navbar() {
  return (
    <>
      <div className="flex flex-col max-w-[1100px] m-auto p-1">
        <div className="flex flex-row text-xs sm:gap-0 gap-2 sm:p-2 py-2 sm:justify-between justify-around font-medium">
          <div className="flex flex-row sm:gap-10 gap-2 flex-wrap">
            <span className="flex flex-row sm:gap-2 gap-1 sm:ml-10">
              <PiPhone size={16} />
              <span>{PageConfig.PHONE_NUMBER}</span></span>
            <span className="flex flex-row sm:gap-2 gap-1 tracking-wider">
              <MdOutlineEmail size={16} />
              <span>{PageConfig.EMAIL}</span>
            </span>
          </div>
          <div className="flex place-self-end self-center right-2 max-sm:pr-2">
            <a
              className="flex flex-row gap-0.5 w-auto transform transition duration-500 hover:scale-[120%] text-end"
              href={PageConfig.INSTAGRAM_URL}
              role="link"
            >
              <FaInstagram size={16} className="max-sm:size-6" />
              <span className="max-sm:hidden">@arco_solar_energia_solar</span>
            </a>
          </div>
        </div>
        <div className="flex flex-row">
          <div className="sm:ml-10">
            <span className="text-2xl">Arco Solar</span>
          </div>
        </div>
      </div>
    </>
  )
}