import { Routes as Switch, Route, Navigate, BrowserRouter } from "react-router-dom";

import Home from "src/pages/Home"
import PrivacyPolitics from "@/pages/PrivacyPolitcs/PrivacyPolitcs"
import TermsAndConditionsPage from "@/pages/TermsAndConditions/TermsAndConditions"

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" element={<Home />} />
        <Route path="/politica-privacidade" element={<PrivacyPolitics />} />
        <Route path="/termos-condicoes" element={<TermsAndConditionsPage />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Switch>
    </BrowserRouter>
  )
}

export default Routes;