// import "styles/tailwind.css"
import { Fragment } from "react";

import { Routes } from "./routes";
import "./tailwind.css";

const App = () => {
  return (
    <Fragment>
      <Routes />
    </Fragment>
  )
}

export default App;