import { forwardRef } from "react";

import { cn } from "@/utils";
import { CircleProps } from "./types"

const CenterCircle = forwardRef<HTMLDivElement, CircleProps>(
  (props, ref) => {
    const { text, className, ...rest } = props;
    return (
      <div className="flex items-center justify-center bg-blue-500 rounded-full text-white sm:size-14 size-12">
        <span className={cn("font-bold sm:text-3xl text-2xl", className)} {...rest} ref={ref}>{text}</span>
      </div>
    )
  }
)

export { CenterCircle };