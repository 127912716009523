import { CenterCircle } from "@/components/ui/Circle";
import { ReactNode } from "react"

interface ComoFuncionaProps {
  children: ReactNode,
  cardTitle: string,
  cardText: string
}

const ComoFuncionaCard = (props: ComoFuncionaProps) => {
  const { children, cardTitle, cardText } = props;
  return (
    <div className="flex flex-row items-center sm:gap-3 gap-2 duration-500 hover:scale-110">
      <div>
        {children}
      </div>
      <div>
        <span className="block text-xl">{cardTitle}</span>
        <p className="text-base font-light">{cardText}</p>
      </div>
    </div>
  )
}

const ComoFuncionaSection = () => {
  return (
    <section className="sm:h-auto sm:p-0 p-4 sm:pt-10">
      <div className="flex flex-col max-w-[1100px] m-auto py-20 items-center text-black">
        <h3 className="text-3xl font-bold uppercase tracking-widest text-slate-700 sm:pb-20 pb-10">Como funciona?</h3>
        <div className="flex flex-col justify-start sm:w-1/2 w-full gap-4 [&_p]:text-base [&_span]:font-semibold">
          <ComoFuncionaCard
            cardTitle="Solicitação"
            cardText="Você nos envia uma conta de energia para fazermos a análise da sua real necessidade."
          >
            <CenterCircle text="1" />
          </ComoFuncionaCard>

          <ComoFuncionaCard
            cardTitle="Proposta"
            cardText="Elaboramos usa proposta e faremos uma vistoria no local."
          >
            <CenterCircle text="2" />
          </ComoFuncionaCard>

          <ComoFuncionaCard
            cardTitle="Contrato"
            cardText="Negocio fechado! Agora é hora de assinar o contrato e o projeto ser iniciado."
          >
            <CenterCircle text="3" />
          </ComoFuncionaCard>

          <ComoFuncionaCard
            cardTitle="Instalação"
            cardText="Com o projeto aprovado, iniciamos o processo de instalação."
          >
            <CenterCircle text="4" />
          </ComoFuncionaCard>

          <ComoFuncionaCard
            cardTitle="Vistoria"
            cardText="Após a instalação, é feita uma vistoria de todo sistema para garantir toda qualidade do projeto."
          >
            <CenterCircle text="5" />
          </ComoFuncionaCard>

          <ComoFuncionaCard
            cardTitle="Economia"
            cardText="Tudo pronto! Seu sistema está funcionando, e o melhor, você está economizando."
          >
            <CenterCircle text="6" />
          </ComoFuncionaCard>

        </div>
      </div>
    </section>
  )
}

export default ComoFuncionaSection;