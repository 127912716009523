import PortfolioImages from "./ImageCarousel";

const PortfolioSection = () => {
  return (
    <section
      id="portfolio"
      className="w-full h-auto bg-slate-100"
    >
      <div className="max-w-[1100px] py-20 p-4 m-auto">
        <div className="flex flex-col justify-center items-center">
          <h3 className="text-3xl font-bold tracking-wider sm:pb-20 pb-10 text-[#072D78] uppercase">Algumas das <strong className="text-[#072D78] font-bold">instalações de sucesso</strong></h3>
          <div className="sm:p-4 sm:w-3/4">
            <PortfolioImages />
          </div>
        </div>
      </div>
    </section >
  )
}

export default PortfolioSection;