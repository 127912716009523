import { Carousel } from 'flowbite-react';

const pernambucoServicesFolder = "/images/servicos/pernambuco/";
const Projetos = [
  { name: "Arco Verde, PE", url: `${pernambucoServicesFolder}arco_verde_1/foto_1.webp`, alt: "Painel Solar em Arco Verde, Pernambuco" },
  { name: "Arco Verde, PE", url: `${pernambucoServicesFolder}arco_verde_2/foto_1.webp`, alt: "Painel Solar em Arco Verde, Pernambuco" },
  { name: "Jaboatão dos Guararapes, PE", url: `${pernambucoServicesFolder}jaboatao_dos_guararapes_1/foto_1.webp`, alt: "Painel Solar em Jaboatão dos Guararapes, Pernambuco" },
  { name: "Piedade, PE", url: `${pernambucoServicesFolder}piedade_1/foto_1.webp`, alt: "Painel Solar em Piedade, Pernambuco" },
];

const PortfolioImages = () => {
  return (
    <Carousel pauseOnHover className='' slide={false}>
      {
        Projetos.map((item, index) => (
          <div className="relative" key={index}>
            <img className='w-full object-contain object-center sm:h-96 h-60 bg-slate-800' src={item.url} width={400} height={0} alt={item.alt} />
          </div>
        ))
      }
    </Carousel>
  )
}

export default PortfolioImages;