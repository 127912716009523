import { DefaultContainer } from "@/components/Container/DefaultContainer"

const questionsAndAnswers: { title: string; description: string }[] = [
  { title: 'Em quanto tempo recupero o investimento? ', description: 'Em aproximadamente de 2 anos' },
  { title: 'Posso gerar energia para quantas casa?', description: 'Não tem um limite máximo de unidades beneficiadas, desde que a conta da neo energia estejam cadastrada em um único CPF.' },
  { title: 'É possível ampliar uma usina solar ?', description: 'Sim, basta adicionar placas caso o sistema tenha um inversor que comporte a aplicação. ' },
  { title: 'É possível mudar uma usina solar de local? ', description: 'Sim, fazemos um novo projeto, para o novo endereço e transferimos a usina de local. ' },
  { title: 'Quando falta energia da concessionária, minha casa continua com energia? ', description: 'Não, o sistema é desligado automaticamente, por segurança. Após o retorno da energia, ele liga automaticamente.' },
  { title: 'Como sei quanto minha usina está gerando ? ', description: 'Nossos projetos, são entregue com sistema de monitoramento, nele consta a geração do sistema do cliente, de forma detalhada.' },
]

const QuestionsAndAnswers = () => {
  return (
    <DefaultContainer className="text-black sm:h-auto p-4">
      <h3 className="w-full text-center text-3xl font-bold uppercase tracking-widest text-slate-700 sm:pb-20 pb-10">Dúvidas Frequentes</h3>
      <div className="flex flex-col gap-6">
        {questionsAndAnswers && questionsAndAnswers.map((question, key) => (
          <details key={key}>
            <summary className="w-full border-t-[1px] border-solid border-black/25 cursor-pointer relative text-xl font-light list-none break-words pr-6">
              {question.title}
            </summary>
            <p>{'>'} {question.description}</p>
          </details>
        ))}
      </div>
    </DefaultContainer>
  )
}

export default QuestionsAndAnswers;