import Layout from "src/components/Layout/Layout";

// import EntrarEmContatoSection from "./components/EntrarEmContatoSection/EntrarEmContatoSection";
import ComoFuncionaSection from "./components/ComoFuncionaSection/ComoFuncionaSection";
import BeneficiosSection from "./components/BeneficiosSection/BeneficiosSection";
import IntrudocaoSection from "./components/IntroductionSection/IntroductionSection";
import PortfolioSection from "./components/PortfolioSection/PortfolioSection";
import QuestionsAndAnswers from "./components/QuestionAndAnswers/QuestionsAndAnswers";
// import SobreSection from "components/Homepage/SobreSection";


const Home = () => {
  return (
    <>
      <Layout title="Arco Solar">
        <IntrudocaoSection />
        <BeneficiosSection />
        <PortfolioSection />
        <ComoFuncionaSection />
        {/* <SobreSection /> */}
        <QuestionsAndAnswers />
        {/* <EntrarEmContatoSection /> */}
      </Layout>
    </>
  );
}

export default Home;