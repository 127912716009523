import { ChangeEvent, Dispatch, SetStateAction, useEffect } from "react"
import { IData } from "src/types";

function TableHead({ text }: { text: string }) {
  return (
    <th className="border-e border-neutral-300 sm:px-3 px-2 py-3" scope="col">{text}</th>
  )
}

function TableData({ text }: { text: string }) {
  return (
    <td className="whitespace-nowrap border-e border-neutral-200 sm:px-3 px-2 py-3 dark:border-white/10 font-medium sm:text-base text-xs" scope="row">{text}</td>
  )
}

function CalculadoraSolar({
  showCalculator,
  setShowCalculator,
  data,
  setData
}: {
  showCalculator: boolean,
  setShowCalculator: Dispatch<SetStateAction<boolean>>,
  data: IData,
  setData: Dispatch<SetStateAction<IData>>,
}) {
  const taxaTUSD = 0.56501497;
  const taxaTE = 0.450339947;

  console.log('teste')

  function onChange(event: ChangeEvent<HTMLSelectElement>) {
    const id = event.target.id;
    const value = event.target.value;

    let newData = {
      ...data,
      [id]: value,
    }

    newData = CalcularValor(newData)
    setData(newData)
  }

  function CalcularValor(newData: IData) {
    const minGeradoDia = (4 * (newData.selectPanel * newData.selectQuantite)) / 1000
    const maxGeradoDia = (6.5 * (newData.selectPanel * newData.selectQuantite)) / 1000

    return {
      ...newData,
      minGeradoDia: minGeradoDia,
      maxGeradoDia: maxGeradoDia,
      minGeradoMes: minGeradoDia * 30,
      maxGeradoMes: maxGeradoDia * 30,
    }
  }

  const arrayRange = (start: number, stop: number, step: number) => {
    const sequence = Array.from(
      { length: (stop - start) / step + 1 },
      (_value, index) => start + index * step
    );
    return sequence;
  }

  useEffect(() => {
    const newData = CalcularValor({ ...data })
    console.log(newData);
    setData(newData)
  }, [])

  return (
    showCalculator && (
      <div
        className="fixed z-50 bg-opacity-40 bg-black inset-0 duration-1000 text-black font-normal tracking-wide"
        onClick={() => setShowCalculator(!showCalculator)}
      >
        <div
          onClick={(event) => event.stopPropagation()}
          className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-50 bg-white rounded-xl max-w-[400px] w-full"
        >
          <div className="flex flex-col items-center sm:p-5 p-4 font-sans">
            <div className="flex flex-row items-center justify-around w-full gap-2">
              <div className="flex flex-col gap-1 p-2">
                <label className="flex items-center justify-center " htmlFor="selectPanel">Tipo de Painel </label>
                <select
                  id="selectPanel"
                  value={data.selectPanel}
                  onChange={(event) => onChange(event)}
                  className="w-24"
                >
                  <option value={550}>550W</option>
                  <option value={555}>555W</option>
                  <option value={560}>560W</option>
                  <option value={580}>580W</option>
                </select>
              </div>
              <div className="flex sm:flex-row flex-col gap-1 p-2">
                <label className="flex items-center justify-center">Quantidade</label>
                <select
                  id="selectQuantite"
                  value={data.selectQuantite}
                  onChange={(event) => onChange(event)}
                  className="w-20"
                >
                  {arrayRange(1, 30, 1).map((item, index) => (
                    <option value={item} key={index}>{item}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="w-full mt-8">
              <table className="text-sm w-full text-center max-w-[400px] m-auto">
                <thead className="border-b border-gray-400 bg-gray-200">
                  <tr className="">
                    <TableHead text="Min Dia" />
                    <TableHead text="R$" />
                    <TableHead text="Max Dia" />
                    <TableHead text="R$" />
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <TableData text={`${data.minGeradoDia.toFixed(2).replace('.', ',')}kWh`} />
                    <TableData text={`${(data.minGeradoDia * (taxaTUSD + taxaTE)).toFixed(2).replace('.', ',')}`} />
                    <TableData text={`${data.minGeradoDia.toFixed(2).replace('.', ',')}kWh`} />
                    <TableData text={`${(data.maxGeradoDia * (taxaTUSD + taxaTE)).toFixed(2).replace('.', ',')}`} />
                  </tr>
                </tbody>
                <thead className="border-b border-gray-400 bg-gray-200">
                  <tr>
                    <TableHead text="Min Mês" />
                    <TableHead text="R$" />
                    <TableHead text="Max Mês" />
                    <TableHead text="R$" />
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <TableData text={`${data.minGeradoMes.toFixed(2).replace('.', ',')}kWh`} />
                    <TableData text={`${(data.minGeradoMes * (taxaTUSD + taxaTE)).toFixed(2).replace('.', ',')}`} />
                    <TableData text={`${data.maxGeradoMes.toFixed(2).replace('.', ',')}kWh`} />
                    <TableData text={`${(data.maxGeradoMes * (taxaTUSD + taxaTE)).toFixed(2).replace('.', ',')}`} />
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  )
}

export default CalculadoraSolar;