import { FaInstagram } from "react-icons/fa"; // Instagram Icon
import { PiPhone } from "react-icons/pi"; // Telefone Icon
import { FaWhatsapp } from "react-icons/fa"; // Whatsapp
import { MdOutlineEmail } from "react-icons/md"; // Email
import { GrMapLocation } from "react-icons/gr"; // Location
import { PageConfig } from "src/libs/config";

export default function Footer() {
  return (
    <footer className="sm:pb-0 pt-20">
      <div className="flex flex-col">
        <div className="flex sm:flex-row justify-center m-auto flex-col w-full max-w-[1100px] [&_h5]:opacity-70 sm:items-start items-center p-4">
          {/* Contato */}
          <div
            className="flex flex-col text-center gap-4 [&_h5]:text-base font-normal [&_span]:font-semibold [&_span]:opacity-85 [&_div]:text-start sm:w-1/2 p-5"
            id="contato"
          >
            <h4 className="text-xl font-bold">Contato</h4>
            <div className="">
              <h5 className="flex flex-row gap-2">
                <i><PiPhone size={24} /></i>
                <span>Telefone e Whatsapp</span></h5>
              <span>(81) 98196-4105</span>
            </div>
            <div>
              <h5 className="flex flex-row gap-2">
                <i><FaWhatsapp size={24} /></i>
                <span>Whatsapp</span>
              </h5>
              <span>(81) 99195-6946</span>
            </div>
            <div>
              <h5 className="flex flex-row gap-2">
                <i><MdOutlineEmail size={24} /></i>
                <span>E-email</span>
              </h5>
              <span>{PageConfig.EMAIL}</span>
            </div>
            <div>
              <h5 className="flex flex-row gap-2">
                <i><GrMapLocation size={24} /></i>
                <span>Endereço</span>
              </h5>
              <span>{PageConfig.ADDRESS}</span>
            </div>

          </div>

          {/* Redes Sociais */}
          <div
            className="flex flex-col gap-4 [&_svg]:opacity-70 sm:w-1/2 w-full items-center sm:items-start sm:p-5 sm:mt-0 mt-8"
            id="redes-sociais"
          >
            <h4 className="text-xl font-bold">Redes Sociais</h4>
            <div className="flex flex-row justify-start gap-4">
              <a
                aria-label="Whatsapp"
                className="flex flex-row gap-0.5 transform transition duration-500 hover:scale-[120%]"
                href={PageConfig.WHATSAPP_CONTATO}
              >
                <i><FaWhatsapp size={40} /></i>
              </a>
              <a
                aria-label="Instagram"
                className="flex flex-row gap-0.5 w-auto transform transition duration-500 hover:scale-[120%]"
                href={PageConfig.INSTAGRAM_URL}
                role="link"
              >
                <i><FaInstagram size={40} /></i>
              </a>
            </div>
            <div className="mt-10">
              <h4 className="text-xl font-bold sm:text-start text-center">Horario de Funcionamento</h4>
              <div className="flex flex-col [&_strong]:font-semibold opacity-90">
                <span>Segunda à Sexta das <strong>08:00 às 17:00</strong></span>
                <span>Sabado das <strong>08:00 às 12:00</strong></span>
              </div>
            </div>
          </div>

        </div>
        <div className="flex flex-row w-full items-center justify-center p-8 pt-10 bg-slate-800">
          <span className="text-white">Todos direitos reservados</span>
        </div>
      </div>
    </footer>
  )
}