import { Dispatch, SetStateAction, Suspense, useState } from "react"
import { Loader } from "lucide-react";

import { IData } from "src/types";
import CalculadoraSolar from "../CalculadoraEnergiaSolar/CalculadoraSolar";
import { introductionInitialData } from "./initialData";

const CalculadoraButton = ({ showCalculator, setShowCalculator }: { showCalculator: boolean, setShowCalculator: Dispatch<SetStateAction<boolean>> }) => {
  return (
    <span
      onClick={() => setShowCalculator(!showCalculator)}
      className="ring-0 ring-offset-yellow-200 p-4 flex items-center justify-center cursor-pointer ring-offset-2 rounded-md ring-yellow-300 transform transition-all duration-500 hover:text-black hover:bg-yellow-300 hover:ring-offset-white">
      Calculadora Solar
    </span>
  )
}

export default function IntrudocaoSection() {
  const [showCalculator, setShowCalculator] = useState(false);
  const [data, setData] = useState<IData>(introductionInitialData);

  return (
    <section
      id="introducao"
      className="flex justify-center relative bg-scroll bg-no-repeat bg-cover bg-bottom sm:h-[700px] h-[500px]"
      style={{ backgroundImage: `url('foto_capa_2.webp')` }}
    >
      <div className="absolute inset-0 bg-black opacity-10" />
      <div className="flex p-4">
        <div className="m-auto relative z-10 text-white">
          <p className="sm:text-xl text-base sm:text-justify text-justify sm:w-[600px] font-semibold h-fit rounded-2xl">
            Diga adeus às altas contas de luz e transforme seu telhado em uma fábrica de energia! Escolha a energia solar da <strong className={`text-yellow-300 tracking-wider`}>Arco Solar</strong>, preço competitivo, energia infinita do sol e instalação profissional de alto padrão.
          </p>
          <div className="flex sm:flex-row flex-col justify-center pt-10 sm:gap-20 gap-4 tracking-wider font-semibold text-center">
            <CalculadoraButton setShowCalculator={setShowCalculator} showCalculator={showCalculator} />
            <Suspense fallback={<Loader className="w-4 h-4 animate-spin" />}>

              <CalculadoraSolar
                showCalculator={showCalculator}
                setShowCalculator={setShowCalculator}
                data={data}
                setData={setData}
              />
            </Suspense>
            {/* <a
              href="#form-contato"
              className="ring-0 bg-green-500 ring-offset-2 ring-green-500 transform transition-all p-4 duration-500 cursor-pointer rounded-md hover:text-green-500 hover:bg-white hover:ring-offset-green-500">
              Entrar em Contato
            </a> */}
          </div>
        </div>

      </div>
    </section>
  )
}