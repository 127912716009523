import Layout from "src/components/Layout"

const TermsAndConditionsPage = () => {
  return (
    <Layout title="Arco Solar | Termos e Condições">
      <div className="bg-slate-200">
        <section className="max-w-[1140px] m-auto bg-white p-4 py-10 [&_h2]:font-semibold text-gray-500 [&_h2]:text-xl [&_h2]:text-black flex flex-col gap-4">
          <div>
            <h2 className="text-black">
              <span>1. Termos</span>
            </h2>
            <p>
              <span>
                Ao acessar ao site{" "}
                <a href="https://arcosolarenergiasolar.com/">Arco Solar Energia Solar</a>,
                concorda em cumprir estes termos de serviço, todas as leis e regulamentos
                aplicáveis e concorda que é responsável pelo cumprimento de todas as
                leis locais aplicáveis. Se você não concordar com algum desses termos,
                está proibido de usar ou acessar este site. Os materiais contidos neste
                site são protegidos pelas leis de direitos autorais e marcas comerciais
                aplicáveis.
              </span>
            </p>
          </div>

          <div>
            <h2>
              <span>2. Uso de Licença</span>
            </h2>
            <p>
              <span>
                É concedida permissão para baixar temporariamente uma cópia dos materiais
                (informações ou software) no site Arco Solar Energia Solar , apenas para
                visualização transitória pessoal e não comercial. Esta é a concessão de
                uma licença, não uma transferência de título e, sob esta licença, você não
                pode:&nbsp;
              </span>
            </p>
            <ol className="pl-5 flex flex-col gap-4">
              <li>
                <span>
                  1. Modificar ou copiar os materiais;&nbsp;
                </span>
              </li>
              <li>
                <span>
                  2. Usar os materiais para qualquer finalidade comercial ou para exibição pública (comercial ou não comercial);
                </span>
              </li>
              <li>
                <span>
                  3. tentar descompilar ou fazer engenharia reversa de qualquer software contido no site Arco Solar Energia Solar;
                </span>
              </li>
              <li>
                <span>
                  4. remover quaisquer direitos autorais ou outras notações de propriedade dos materiais; ou
                </span>
              </li>
              <li>
                <span>
                  5. transferir os materiais para outra pessoa ou 'espelhe' os materiais em qualquer outro servidor.
                </span>
              </li>
            </ol>
            <p>
              <span>
                Esta licença será automaticamente rescindida se você violar alguma dessas
                restrições e poderá ser rescindida por Arco Solar Energia Solar a qualquer
                momento. Ao encerrar a visualização desses materiais ou após o término
                desta licença, você deve apagar todos os materiais baixados em sua posse,
                seja em formato eletrónico ou impresso.
              </span>
            </p>
          </div>
          <div>
            <h2>
              <span>
                3. Isenção de responsabilidade
              </span>
            </h2>
            <ol>
              <li>
                <span>
                  1. Os materiais no site da Arco Solar Energia Solar são fornecidos 'como
                  estão'. Arco Solar Energia Solar não oferece garantias, expressas ou
                  implícitas, e, por este meio, isenta e nega todas as outras garantias,
                  incluindo, sem limitação, garantias implícitas ou condições de
                  comercialização, adequação a um fim específico ou não violação de
                  propriedade intelectual ou outra violação de direitos.
                </span>
              </li>
              <li>
                <span>
                  2. Além disso, o Arco Solar Energia Solar não garante ou faz qualquer
                  representação relativa à precisão, aos resultados prováveis ou à
                  confiabilidade do uso dos materiais em seu site ou de outra forma
                  relacionado a esses materiais ou em sites vinculados a este site.
                </span>
              </li>
            </ol>
          </div>

          <div>
            <h2>
              <span>4. Limitações</span>
            </h2>
            <p>
              <span>
                Em nenhum caso o Arco Solar Energia Solar ou seus fornecedores serão
                responsáveis por quaisquer danos (incluindo, sem limitação, danos por
                perda de dados ou lucro ou devido a interrupção dos negócios) decorrentes
                do uso ou da incapacidade de usar os materiais em Arco Solar Energia
                Solar, mesmo que Arco Solar Energia Solar ou um representante autorizado
                da Arco Solar Energia Solar tenha sido notificado oralmente ou por escrito
                da possibilidade de tais danos. Como algumas jurisdições não permitem
                limitações em garantias implícitas, ou limitações de responsabilidade por
                danos conseqüentes ou incidentais, essas limitações podem não se aplicar a
                você.
              </span>
            </p>
          </div>

          <div>
            <h2>
              <span>5. Precisão dos materiais</span>
            </h2>
            <p>
              <span>
                Os materiais exibidos no site da Arco Solar Energia Solar podem incluir
                erros técnicos, tipográficos ou fotográficos. Arco Solar Energia Solar não
                garante que qualquer material em seu site seja preciso, completo ou atual.
                Arco Solar Energia Solar pode fazer alterações nos materiais contidos em
                seu site a qualquer momento, sem aviso prévio. No entanto, Arco Solar
                Energia Solar não se compromete a atualizar os materiais.
              </span>
            </p>
          </div>

          <div>
            <h2>
              <span>6. Links</span>
            </h2>
            <p>
              <span>
                O Arco Solar Energia Solar não analisou todos os sites vinculados ao seu
                site e não é responsável pelo conteúdo de nenhum site vinculado. A
                inclusão de qualquer link não implica endosso por Arco Solar Energia Solar
                do site. O uso de qualquer site vinculado é por conta e risco do usuário.
              </span>
            </p>
            <p>
              <br />
            </p>
          </div>

          <div>
            <h3>
              <span>Modificações</span>
            </h3>
            <p>
              <span>
                O Arco Solar Energia Solar pode revisar estes termos de serviço do site a
                qualquer momento, sem aviso prévio. Ao usar este site, você concorda em
                ficar vinculado à versão atual desses termos de serviço.
              </span>
            </p>
            <h3>
              <span>Lei aplicável</span>
            </h3>
            <p>
              <span>
                Estes termos e condições são regidos e interpretados de acordo com as leis
                do Arco Solar Energia Solar e você se submete irrevogavelmente à
                jurisdição exclusiva dos tribunais naquele estado ou localidade.
              </span>
            </p>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default TermsAndConditionsPage;