import { ReactNode } from "react"

import { TbPigMoney } from "react-icons/tb"; // Porco de dinheiro
import { MdCo2 } from "react-icons/md"; // CO2
import { TbSolarPanel2 } from "react-icons/tb"; // Painel Solar
import { GiBroom } from "react-icons/gi"; // Vassoura
import { FaHouse } from "react-icons/fa6"; // Casa
import { RiMoneyDollarCircleFill } from "react-icons/ri"; // Moeda

function BeneficiosCard({
  icon,
  spanText,
}: {
  icon: ReactNode,
  spanText: string,
}) {
  return (
    <div className="flex flex-col items-center sm:gap-4 align-baseline">
      <i>{icon}</i>
      <span>{spanText}</span>
    </div>
  )
}

function BeneficiosSection() {
  return (
    <section className="sm:h-auto">
      <div className="flex m-auto flex-col items-center max-w-[1100px] py-20">
        <div className="">
          <h3 className="text-3xl uppercase font-bold tracking-wider text-blue-600 sm:pb-20 pb-10 text-center">Quais são os Benefícios?</h3>
        </div>
        <div className="flex flex-row text-slate-700 flex-wrap justify-center [&_div]:flex [&_div]:flex-col [&_div]:items-center [&_div]:w-[300px] m-auto font-medium text-center place-items-center sm:gap-16 gap-6">
          <BeneficiosCard
            icon={<MdCo2 size={100} />}
            spanText="Redução da conta de energia em cerca de 90%"
          />
          <BeneficiosCard
            icon={<TbPigMoney size={100} />}
            spanText="Redução da poluição e das taxas de carbono"
          />
          <BeneficiosCard
            icon={<TbSolarPanel2 size={100} />}
            spanText="Longa vida útil dos paineis solares"
          />
          <BeneficiosCard
            icon={<GiBroom size={100} />}
            spanText="Baixíssima necessidade de manutenção"
          />

          <div className="flex flex-col items-center sm:gap-4 align-baseline">
            <div className="flex flex-col items-center sm:gap-4 align-baseline">
              <div className="relative flex items-center justify-center">
                <FaHouse size={100} />
                <div className="absolute top-0 right-[33%] !w-auto bg-white rounded-full">
                  <i><RiMoneyDollarCircleFill size={30} /></i>
                </div>
              </div>
              <span>Valorização do imóvel</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BeneficiosSection;